import { SHIPPING_METHODS_LIST } from "~/lib/constants";

export const getExclusiveDeliveryMethodMessage = (product: KippieProduct) => {
	let customFields = product.productCustomFields;
	if (!customFields) {
		customFields = product.customFields;
	}

	const methodIds = customFields?.kippie_product_fields_available_shipping_methods;
	if (!methodIds || methodIds.length > 1 || methodIds.length === 0) return;

	const exclusiveMethod = SHIPPING_METHODS_LIST.find((method) => method.id === methodIds[0]);
	if (!exclusiveMethod) return;

	return exclusiveMethod.type === "delivery" ? "Alleen voor bezorging" : "Alleen in de winkel";
};
